import React, { useEffect, useState } from "react";

function TxtData() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const userName = sessionStorage.getItem("fabricUserName");

    const fetchData = async () => {
      const merchantData = await fetch("/temp/express_backend");
      const p2pData = await fetch("/temp/p2p_transactions");

      let merchantDataText = await merchantData.text();
      let p2pDataText = await p2pData.text();

      const merchantDataArr = Object.values(JSON.parse(merchantDataText));
      const p2pDataArr = Object.values(JSON.parse(p2pDataText));

      const mergedData = [...merchantDataArr, ...p2pDataArr]
        .filter((tx) => tx.value.name === userName || tx.value.to === userName)
        .map((tx) => ({
          ...tx,
          value: {
            ...tx.value,
            tx_amount:
              tx.value.to === userName
                ? `$${tx.value.tx_amount}`
                : `-$${tx.value.tx_amount}`,
            tx_type: tx.value.to === userName ? "Recieved" : tx.value.tx_type,
            location:
              tx.value.to === userName
                ? `From: ${tx.value.name}`
                : tx.value.tx_type === "Sent"
                ? `To: ${tx.value.to}`
                : tx.value.location,
          },
        }))
        .reverse();

      setData(mergedData.slice(0, 10));
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="tx-wrapper">
        {data.map((tx, i) => (
          <div key={i}>
            {
              <div className="tx-box">
                <p className="tx-type"> {tx.value.tx_type} </p>
                <p className="tx-date"> {tx.value.timestamp} </p>
                <p className="tx-loc"> {tx.value.location} </p>
                <p className="tx-amount"> {tx.value.tx_amount} </p>
              </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
}

export default TxtData;
